import validate from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/pages/runtime/validate.js";
import authorization_45global from "/src/middleware/authorization.global.ts";
import i18n_45global from "/src/middleware/i18n.global.ts";
import metadata_45global from "/src/middleware/metadata.global.ts";
import shop_45init_45routes_45global from "/src/middleware/shopInitRoutes.global.js";
import manifest_45route_45rule from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authorization_45global,
  i18n_45global,
  metadata_45global,
  shop_45init_45routes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}