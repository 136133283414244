import { useRouteStore } from '~/store/routesStore'

export default defineNuxtRouteMiddleware(async (to) => {
  const pageId = to.meta.pageId

  if (!pageId) {
    console.warn('Impossible to get metadata: pageId not found in route meta.', to.path)
  } else {
    useRouteStore().setCurrentRouteByPageId(pageId)
  }

  usePageMetadata()
})
