import revive_payload_client_DGebgT115U from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_l9PJztRVrv from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VKWwKNNuiY from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_jONfnVabtS from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UjT5Ldl0LU from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_KmyDvSTbsE from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8bVsK7Prrz from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_Qd0DZMucda from "/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.75_db0@0.2.4_eslint@8.57.1_ioredis@_4ec621207f7dc8843986d65c20322078/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_U8ngieIJFF from "/src/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue_b28aaac30f7bc435bd4fc3c0c1f00d18/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_YWJCB0VwLn from "/src/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_client_j4jpWgJhim from "/src/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_hjCrT3Mgqd from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_xrY8REeVYf from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import i18n_loader_KkCDvE77RQ from "/src/plugins/i18n-loader.js";
import alert_1l4BSaq8Wt from "/src/plugins/alert.ts";
import floating_vue_BiOD74u9sH from "/src/plugins/floating-vue.ts";
import grafana_client_jGF9op8sVg from "/src/plugins/grafana.client.js";
import kustomer_client_g2QShyDNSq from "/src/plugins/kustomer.client.js";
import ometria_client_pYMHeAHj8Q from "/src/plugins/ometria.client.js";
import redirect_CeAqr2JrWj from "/src/plugins/redirect.js";
import swiper_eteSCvuYkd from "/src/plugins/swiper.ts";
import vue_the_mask_3Ai82AaTlI from "/src/plugins/vue-the-mask.js";
export default [
  revive_payload_client_DGebgT115U,
  unhead_l9PJztRVrv,
  router_VKWwKNNuiY,
  payload_client_jONfnVabtS,
  navigation_repaint_client_UjT5Ldl0LU,
  check_outdated_build_client_KmyDvSTbsE,
  chunk_reload_client_8bVsK7Prrz,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Qd0DZMucda,
  plugin_U8ngieIJFF,
  plugin_client_YWJCB0VwLn,
  plugin_client_j4jpWgJhim,
  switch_locale_path_ssr_hjCrT3Mgqd,
  i18n_xrY8REeVYf,
  i18n_loader_KkCDvE77RQ,
  alert_1l4BSaq8Wt,
  floating_vue_BiOD74u9sH,
  grafana_client_jGF9op8sVg,
  kustomer_client_g2QShyDNSq,
  ometria_client_pYMHeAHj8Q,
  redirect_CeAqr2JrWj,
  swiper_eteSCvuYkd,
  vue_the_mask_3Ai82AaTlI
]