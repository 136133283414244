import { useSetupTranslations } from '~/composables/useSetupTranslations'
import { LANGUAGES_CODE } from '~/constants/languages'

export default defineNuxtRouteMiddleware(async (to) => {
  const languageCode = getLanguageCodeFromRoute(to) || LANGUAGES_CODE.ENGLISH

  const pageId = to.meta.pageId // new shop routes do not have item id,  shop translations come with common translations

  if (!pageId) {
    console.warn('Impossible to get translations: pageId not found in route meta.', to.path)
    return
  }

  const { setupTranslationsByPageId } = await useSetupTranslations()

  await setupTranslationsByPageId(pageId, languageCode)
})
