export function useJsonLd() {
  const setProductItemSchema = (productData: Ref<Record<string, string>>) => {
    if (!productData?.name) {
      console.error('setProductItemSchema: error to generate product item json ld')
      return
    }

    useHead({
      script: [
        {
          id: 'product-item-json-ld',
          type: 'application/ld+json',
          innerHTML: JSON.stringify(productData),
        },
      ],
    })
  }

  return { setProductItemSchema }
}
