export const PUBLIC_PAGES_ROUTES = {
  CHECKOUT: '/checkout',
  CONTACT: '/contact',
  DELIVERY_AND_RETURNS: '/delivery-and-returns',
  REGISTER: '/register',
  SHOP_BAG: '/shop-bag',
  SHOWROOMS: '/showrooms',
}

export const SEO_PAGES_ROUTES = {
  BESPOKE_STANDOUT_PIECES: '/bespoke-standout-pieces',
  CUPID_DIAMOND: '/cupids-ideal-diamonds',
  LAB_GROWN_CUPID_DIAMOND: '/cupids-ideal-lab-grown-diamonds',
}

export const PRIVATE_PAGES_ROUTES = {
  ADDRESS: '/account/addresses',
  ORDERS: '/account/orders',
  PRIVACY: '/account/privacy',
  PROFILE: '/account/profile',
}

export const AUTH_PAGES_ROUTES = {
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  LOGOUT: '/logout',
}

export const POST_PAYMENT_PAGES_ROUTES = {
  CARD: '/payments-return/card',
  FAILED: '/failed',
  PAYPAL: '/paypal-return',
  SPLITIT: '/payments-return/splitit',
  SUCCESS: '/success',
}

export const POST_PAYMENT_PAGES_ROUTES_ID = {
  [POST_PAYMENT_PAGES_ROUTES.FAILED]: 19,
  [POST_PAYMENT_PAGES_ROUTES.PAYPAL]: 242,
  [POST_PAYMENT_PAGES_ROUTES.SUCCESS]: 3,
}

export const PUBLIC_PAGES_ROUTES_ID = {
  [PUBLIC_PAGES_ROUTES.CHECKOUT]: 26,
  [PUBLIC_PAGES_ROUTES.SHOP_BAG]: 4,
}

export const SEO_PAGES_ROUTES_ID = {
  [SEO_PAGES_ROUTES.CUPID_DIAMOND]: 717,
  [SEO_PAGES_ROUTES.LAB_GROWN_CUPID_DIAMOND]: 700,
  [SEO_PAGES_ROUTES.BESPOKE_STANDOUT_PIECES]: 684,
}
