export const useFinances = () => {
  const getV12FinanceOptions = async (payload: { amount: number; currencyId: number }) =>
    fetchWithLoader('/api/checkout/v12-finance-calculator', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    })

  const getProductFinanceOption = async (payload: { countryId: number; currencyId: number; amount: number }) => {
    const response = await fetchWithLoader(`/api/v1/payment/finance-option`, {
      method: 'GET',
      query: {
        countryId: payload.countryId,
        currencyId: payload.currencyId,
        Amount: payload.amount,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  return {
    getV12FinanceOptions,
    getProductFinanceOption,
  }
}
