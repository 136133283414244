import type { CountryItem, Currency, Language, LanguageSelection, userPreferences } from '~/types/currencySelection'

import { fetchSettingsExpressSettings, fetchSettingsShopSettings } from '~/api/settings'

import expressSettingsConstant from '@/constants/expressSettings'
import { CONTACT_PHONE } from '~/constants/contacts'
import { LANGUAGES_CODE } from '~/constants/languages'
import { ExchangeRate } from '~/api/app'

export const useNewSettingsStore = defineStore('newSettings', () => {
  const { getCountries, getCurrencies, getPhoneNumbers, getLanguages, getCompany, getCurrencySymbol } = useUtils()
  const { $i18n } = useNuxtApp()
  const scrollPosition = ref<number | null>(null)
  const countries = ref<CountryItem[]>([])
  const currentCountry = ref<CountryItem>(null)
  const currentCountryId = useCookie('currentCountryId')
  const currencies = ref<Currency[]>([])
  const currentCurrency = ref<Currency>(null)
  const currentCurrencyId = useCookie('currentCurrencyId')
  const currencySymbol = useCookie('currencySymbol')
  const countryCompany = ref<Currency>(null)
  const languages = ref<LanguageSelection[]>([])
  const currentLanguage = ref<Language>(null)
  const currentLanguageId = useCookie('currentLanguageId')
  const phoneNumbers = ref<any[]>([])
  const userPreferences = ref<userPreferences | null>(null)
  //ToDo: types
  const diamondPreferences = ref<any | null>(null)
  // The constant part of `expressSettings` must be available immediately because it is used in shop requests and cannot be delayed.
  const expressSettings = ref<any>(expressSettingsConstant()) // expressSettingsConstantData is constant, dynamic part added from API
  const shopSettings = ref<any | null>(null)
  const exchangeRate = ref<number>(1)

  const getLanguageCurrencyCode = computed(() => {
    const countryCode = currentCountry.value?.CountryCodeISO || userPreferences.value?.CountryName || 'GB'
    const currencyCode = currentCurrency.value?.Name || userPreferences.value?.CurrencyCode || 'GBP'
    return `${countryCode} / ${currencyCode}`
  })

  const getCurrentCurrencySymbol = computed(() => {
    return currencySymbol.value || userPreferences.value?.CurrencySymbol || '£'
  })

  const countryPhoneNumber = computed(() => {
    return phoneNumber.value || currentCountry.value?.CountryPhoneNumber || CONTACT_PHONE
  })

  const currentLanguageCode = computed(() => currentLanguage.value?.Code)

  const currentLanguageCodeExcludeEnglish = computed(() =>
    currentLanguageCode.value === LANGUAGES_CODE.ENGLISH ? '' : currentLanguageCode.value,
  )

  const phoneNumber = computed(() => {
    const phoneNumberToSet = phoneNumbers.value.find((numberInfo) => numberInfo.Id === currentCountry.value.Id)?.Number

    if (phoneNumberToSet) {
      return phoneNumberToSet
    }

    return undefined
  })

  const getLanguageById = (id: number) => {
    const result = languages.value.LanguageSelections?.find((item) => item.Language.Id === id)

    if (!result?.Language) {
      return LANGUAGES_CODE.ENGLISH
    }

    return result.Language.Code
  }

  const getLanguageCodeByIdExcludeEnglish = (id: number) => {
    const languageCodeById = getLanguageById(id)

    return languageCodeById === LANGUAGES_CODE.ENGLISH ? '' : languageCodeById
  }

  const handleGetExpressSettings = async () => {
    const response = await fetchSettingsExpressSettings()

    expressSettings.value = { ...expressSettings.value, ...response }
  }

  const setCountries = async () => {
    countries.value = await getCountries()
  }

  const setCurrentCountry = (country?: CountryItem) => {
    if (country) {
      currentCountry.value = country
      currentCountryId.value = country.Id

      handleGetExpressSettings()
      return
    }

    if (!countries.value) {
      return
    }
    const countryIdToFind = currentCountryId.value || userPreferences.value?.CountryId
    const result = countries.value?.find((c) => c.Id === countryIdToFind)

    if (result) {
      currentCountry.value = result
      currentCountryId.value = result.Id
      userPreferences.value.CountryId = result.Id

      handleGetExpressSettings()
    }
  }

  const setCurrentCurrency = (currency?: Currency) => {
    if (currency) {
      currentCurrency.value = currency
      currentCurrencyId.value = currency.Id
      // reloadNuxtApp()
      return
    }
    if (!currencies.value) {
      return
    }
    const currencyIdToFind = currentCurrencyId.value || userPreferences.value.CurrencyId
    const result = currencies.value?.find((c) => c.Id === currencyIdToFind)

    if (result) {
      currentCurrency.value = result
      currentCurrencyId.value = result.Id
      userPreferences.value.CurrencyId = result.Id
    }
  }

  const updateCurrentCurrencySymbol = () => {
    currencySymbol.value = getCurrencySymbol(currentCurrencyId.value)
  }

  const setCurrencies = async () => {
    currencies.value = await getCurrencies()
  }

  const setCompany = async (countryId: undefined | number | string = currentCountryId.value) => {
    countryCompany.value = await getCompany(countryId)
  }

  const setLanguages = async () => {
    const languageId = $i18n.locales.value.find((locale) => locale.code === $i18n.locale.value)?.id || 1
    languages.value = await getLanguages(languageId, currentCountry.value?.Id, currentCurrency.value?.Id)
  }

  const setPhoneNumbers = async () => {
    phoneNumbers.value = await getPhoneNumbers()
  }

  const setCurrentLanguage = (lang?: Language) => {
    if (lang) {
      currentLanguage.value = lang
      currentLanguageId.value = lang.Id
    } else {
      const languageId = $i18n.locales.value.find((locale) => locale.code === $i18n.locale.value)?.id || 1
      const languageIdToFind = languageId
      const language = languages.value?.LanguageSelections.find(
        (language) => Number(language.Language.Id) === languageIdToFind,
      )?.Language
      currentLanguage.value = language
      currentLanguageId.value = language.Id
    }
  }

  const setUserPreferences = (preferences: userPreferences) => {
    userPreferences.value = preferences
  }

  const setDiamondPreferences = (preferences: any) => {
    diamondPreferences.value = preferences
  }

  const setShopSettings = async () => {
    const settings = await fetchSettingsShopSettings()
    shopSettings.value = settings
  }

  const getExchangeRate = async () => {
    exchangeRate.value = await ExchangeRate()
  }

  watch(
    () => currentCountry.value,
    () => {
      setCompany()
    },
  )

  watch(
    () => currentLanguage.value,
    () => {
      setLanguages()
    },
  )

  return {
    scrollPosition,
    currentCountry,
    currentCountryId,
    currencySymbol,
    currentCurrency,
    currentLanguage,
    currentLanguageId,
    phoneNumber,
    userPreferences,
    diamondPreferences,
    getLanguageCurrencyCode,
    getCurrentCurrencySymbol,
    countryPhoneNumber,
    countryCompany,
    exchangeRate,
    setCurrentCountry,
    setCurrentCurrency,
    setCurrentLanguage,
    setUserPreferences,
    setDiamondPreferences,
    setCountries,
    setCurrencies,
    setCompany,
    setLanguages,
    setPhoneNumbers,
    handleGetExpressSettings,
    setShopSettings,
    updateCurrentCurrencySymbol,
    countries,
    currencies,
    languages,
    phoneNumbers,
    expressSettings,
    shopSettings,
    currentLanguageCode,
    currentLanguageCodeExcludeEnglish,
    getExchangeRate,
    getLanguageCodeByIdExcludeEnglish,
  }
})
