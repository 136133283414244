import { fetchTranslationAttributes, fetchTranslationByPageId } from '~/api/translations'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { LANGUAGES, LANGUAGES_CODE } from '~/constants/languages'

type PageId = string | number
type LanguageCode = string

export const useSetupTranslations = async () => {
  const nuxtApp = useNuxtApp()
  const newSettingsStore = useNewSettingsStore()

  const handleUpdateTranslations = (languageCode: LanguageCode, newTranslations) =>
    nuxtApp.$i18n.setLocaleMessage(languageCode, newTranslations)

  const setupTranslationsByPageId = async (pageId: PageId, languageCode: LanguageCode) => {
    try {
      const newPageTranslations = await fetchTranslationByPageId({ pageId, languageCode })

      if (!newPageTranslations) {
        console.error('No translation found for', `pageId: ${pageId}, localeCode: ${languageCode}`)
        return
      }

      const currentTranslations = nuxtApp.$i18n.getLocaleMessage(languageCode)
      const mergedTranslations = { ...currentTranslations, ...newPageTranslations }

      handleUpdateTranslations(languageCode, mergedTranslations)
    } catch (error) {
      console.error(error)
    }
  }

  const setupAttributesTranslations = async () => {
    try {
      const languageId = newSettingsStore.currentLanguage?.Id || LANGUAGES.ENGLISH
      const languageCode = newSettingsStore.currentLanguage?.Code || LANGUAGES_CODE.ENGLISH
      const newAttributesTranslations = await fetchTranslationAttributes(languageId)

      if (!newAttributesTranslations) {
        console.error(`No attributes translations found for, language code: ${languageCode}`)
        return
      }

      const currentTranslations = nuxtApp.$i18n.getLocaleMessage(languageCode)
      const mergedTranslations = { ...currentTranslations, ...newAttributesTranslations }

      handleUpdateTranslations(languageCode, mergedTranslations)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    setupAttributesTranslations,
    setupTranslationsByPageId,
  }
}
