export const CDN_PATH = 'https://cdn.77diamonds.com' // TODO: remove when we settle image handling
export const PROD_ORIGIN = 'https://www.77diamonds.com'
export const LOCAL_STORAGE_PREFIX = '_77'
export const DEFAULT_LOCALE = 'en'
export const DEFAULT_EN_COUNTRY_CODE = 'en-gb'
export const PROD_ORIGIN_IMAGES = `${PROD_ORIGIN}/images/`

// export const bffLink = window?.bffLink || 'https://bff.77diamondslocal.com'
// TODO: maybe store authLink and bffLink instead of window in nuxt config, not sure that it should be in window

//TODO move these base apis routes to .env
export const API_BASES = {
  development: 'https://website-bff-api.dev.77diamonds.com',
  production: 'https://website-bff-api.77diamonds.com',
  staging: 'https://website-bff-api.staging.77diamonds.com',
}

const TRANSLATION_BASE_API = 'https://seventysevensandbox.blob.core.windows.net/translations-dev'
export const TRANSLATION_API = `${TRANSLATION_BASE_API}`
export const TRANSLATION_ATTRIBUTES_API = `${TRANSLATION_BASE_API}/attributes.js`

//old bffLink
export const API_BASE = API_BASES.staging

export const bffLink = API_BASE

//old authLink
export const AUTH_API_BASES = {
  development: 'https://auth.dev.77diamonds.com',
  production: 'https://auth.77diamonds.com',
  staging: 'https://auth.staging.77diamonds.com',
}

export const AUTH_API_BASE = AUTH_API_BASES.staging

export const SHOP_ROUTES_NAMES = {
  ITEM: 'item',
  DIAMOND: 'diamond',
  CATEGORY: 'category',
  ITEM_FINISH: 'item-finish',
  ITEM_DIAMOND: 'diamond',
  ITEM_WITH_DIAMOND: 'item-diamond',
  DIAMOND_DETAILS: 'diamond-details',
  ITEM_DIAMOND_DETAILS: 'item-diamond-details',
}
