export const ORDER_TYPES = {
  Amazon: 11,
  ApplePrimer: 18,
  BankTransfer: 5,
  BuyBack: 12,
  Klarna: 16,
  Manual: 6,
  ManualEmail: 15,
  ManualOnsite: 14,
  OnlineCreditCard: 1,
  OnlineFinance: 7,
  OnlineGoogleCheckOut: 3,
  OnlinePayPal: 2,
  Phone: 4,
  Repair: 10,
  Resize: 9,
  Return: 13,
  SplitIt: 17,
  VoltPrimer: 19,
}

export const CONFIRMATION_ORDER_PARAMETERS = {
  amazon: 'aoc77',
  checkout: 'soc77',
  stripe: 'soc77',
}

export const NON_ORDER_TYPES_PAYMENT_INSTANT = [ORDER_TYPES.Amazon, ORDER_TYPES.BankTransfer]
