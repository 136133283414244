import { TRANSLATION_API } from '~/constants'

type Payload = {
  pageId: string | number
  languageCode: string
}

export async function fetchTranslationByPageId(payload: Payload): Promise<Record<string, string> | undefined> {
  const translationRoute = `${TRANSLATION_API}/${payload.languageCode}/${payload.pageId}.js`

  try {
    const routeTranslations = await $fetch(translationRoute)
    const routeTranslationsText = await routeTranslations.text()

    return JSON.parse(routeTranslationsText)
  } catch (error) {
    console.error('Failed to fetch translation', translationRoute)
    console.error(error)
    throw error // Rethrow to handle in the calling function
  }
}

export async function fetchTranslationAttributes(
  languageId: string | number,
): Promise<Record<string, string> | undefined> {
  try {
    return await $fetch(`/api/translations/attributes/${languageId}`)
  } catch (error) {
    console.error('Failed to fetch attributes translations', translationRoute)
    console.error(error)
    throw error
  }
}
