export default {
  itemId: null,
  metalId: null,
  categoryId: 0,
  currentPage: 1,
  resultsPerPage: 20,
  stoneType: 0,
  diamondType: 0,
  minCarat: 0.3,
  maxCarat: 30,
  colors: null,
  clarities: null,
  certificates: null,
  cuts: null,
  polishes: null,
  symmetries: null,
  fluorescences: null,
  intensities: null,
  shapes: null,
  isGroupedShapes: false,
  showPairs: false,
  minPrice: 100,
  maxPrice: 5000000,
  minRatio: 1,
  maxRatio: 5,
  withMedia: false,
  selectedDiamonds: null,
  minDepth: 0,
  maxDepth: 0,
  minTable: 0,
  maxTable: 0,
  quickShipping: false,
  gemType: null,
  gemClarity: null,
  gemIntensity: null,
  gemTreatment: null,
  gemOrigin: null,
  gemClarityTreatment: null,
  searchBlocked: false,
  language: 0,
  country: 0,
  currency: 0,
  'discount-code': null,
  diamondsInBag: null,
  url: null,
}
