type KebabKeys<T> = { [K in keyof T as K extends string ? Kebab<K> : K]: T[K] }

export function formatMessageWithBreaks(message: string) {
  const lineBreakPattern = /\r\n|\r|\n|\\n/
  const lines = message.split(lineBreakPattern)

  return lines.join('<br/><br/>')
}

export function generateTranslateKeyFromValue(value: string): KebabKeys<string> {
  const wordList = value.split(' ')
  const isOneWord = wordList.length === 1
  const trimedLabel = value.trim()
  const loweredLabel = trimedLabel.toLowerCase()

  if (isOneWord) {
    return loweredLabel
  }

  return loweredLabel.replaceAll(' ', '-')
}
