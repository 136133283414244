export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","href":"https://cdn.77diamonds.com/images/NewImages/Icons/Favicon.png","type":"image/x-icon"},{"rel":"apple-touch-icon","href":"https://cdn.77diamonds.com/images/NewImages/Icons/77D_57x57.png","sizes":"57x57"},{"rel":"apple-touch-icon","href":"https://cdn.77diamonds.com/images/NewImages/Icons/77D_72x72.png","sizes":"72x72"},{"rel":"apple-touch-icon","href":"https://cdn.77diamonds.com/images/NewImages/Icons/77D_114x114.png","sizes":"114x114"},{"rel":"apple-touch-icon","href":"https://cdn.77diamonds.com/images/NewImages/Icons/77D_144x144.png","sizes":"144x144"},{"rel":"image_src","href":"https://cdn.77diamonds.com/images/NewImages/Icons/77D_144x144.png"}],"style":[],"script":[{"src":"//cdn.ometria.com/tags/e96386f4b18b56df.js","async":true,"defer":true},{"src":"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js","async":true,"defer":true},{"src":"https://accounts.google.com/gsi/client","async":true,"defer":true},{"src":"https://cdn.ywxi.net/js/1.js","async":true,"defer":true},{"src":"https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js","async":true,"defer":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false