interface UserPreferences {
  countryId: number | undefined
  currencyId: number | undefined
  languageId: number | undefined
  discountCode: string | undefined
}

interface SaveOrderDetailsParams {
  model: any
  checkoutStep: number
  userPreference: UserPreferences
}

interface PayWithCardParams {
  model: any
  userPreference: UserPreferences
  redirect3dsUrl: string
  token?: string
}

interface PaypalReturnParams {
  DiscountCode?: string
  FromMobile: boolean
  IsGbmh: boolean
  LanguageId: number
  PaypalToken: string
}

interface PayWithV12Params {
  FinanceProductGuid: string
  FromMobile?: boolean
  IsAuthenticated?: boolean
  UserPreference: {
    CountryId: number
    CurrencyId: number
    DiscountCode: string
    LanguageId: number
  }
}

interface V12ReturnData {
  ApplicationId: number
  AuthorizationNumber: string
  Flag: number
  LanguageId: number
  OrderId: number
  SecondSalesReference: string
  Status: string
}

export const useCheckout = () => {
  const runtimeConfig = useRuntimeConfig()
  const webApi = runtimeConfig.public.apiWeb

  const initCheckoutData = (payload: { userPreference: UserPreferences }) => {
    return fetchWithLoader('/api/checkout/process-page', {
      method: 'POST',
      body: payload,
    })
  }

  const getFinalFinanceOption = (userPreferences: UserPreferences) => {
    return fetchWithLoader('/api/checkout/finance-option', {
      method: 'POST',
      body: JSON.stringify(userPreferences),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const saveOrderDetails = ({ model, checkoutStep, userPreference }: SaveOrderDetailsParams) => {
    return fetchWithLoader('/api/checkout/save-step', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        step: checkoutStep + 1,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const payWithCard = ({ model, userPreference, token, redirect3dsUrl }: PayWithCardParams) => {
    return fetchWithLoader('/api/checkout/payment-url', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        IsAuthenticated: false,
        Redirect3dsUrl: redirect3dsUrl,
        CheckoutToken: token,
        FromMobile: false,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const payWithBankTransfer = ({ model, userPreference }: PayWithCardParams) => {
    return fetchWithLoader('/api/checkout/payment-bank-transfer', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const payWithSplitit = ({ model, userPreference, callbackUrl }: PayWithCardParams & { callbackUrl: string }) => {
    return fetchWithLoader('/api/checkout/payment-splitit', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        callbackUrl,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const payWithFinanceV12 = (payload: PayWithV12Params) => {
    return fetchWithLoader('/api/checkout/payment-v12', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const handleSplititReturn = (installmentNumber: string) =>
    fetchWithLoader('/api/checkout/return-splitit', {
      method: 'POST',
      body: JSON.stringify({
        InstallmentPlanNumber: installmentNumber,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

  const handleCardReturn = (checkoutSessionId: string) =>
    fetchWithLoader('/api/checkout/return-card', {
      method: 'POST',
      body: JSON.stringify({
        CheckoutSessionId: checkoutSessionId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

  const handleV12Return = (returnData: V12ReturnData) =>
    fetchWithLoader('/api/checkout/return-v12', {
      method: 'POST',
      body: JSON.stringify(returnData),
      headers: {
        'Content-Type': 'application/json',
      },
    })

  const getSuccessData = (payload: { userPreference: UserPreferences }) => {
    return fetchWithLoader('/api/checkout/success', {
      method: 'POST',
      body: payload,
    })
  }

  const getFailedData = (payload: { userPreference: UserPreferences }) => {
    return fetchWithLoader('/api/checkout/failed', {
      method: 'POST',
      body: payload,
    })
  }

  const payWithPaypal = ({
    model,
    userPreference,
    paypalReturnUrl,
    paypalCancelUrl,
  }: PayWithCardParams & {
    paypalReturnUrl: string
    paypalCancelUrl: string
  }) => {
    return fetchWithLoader('/api/checkout/payment-paypal', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        paypalCallBackUrl: `${webApi}/api/v1/payments/paypal/callback`,
        paypalReturnUrl,
        paypalCancelUrl,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const handlePaypalReturn = (paypalReturnPayload: PaypalReturnParams) => {
    const { PaypalToken, LanguageId, IsGbmh, DiscountCode = '' } = paypalReturnPayload

    return fetchWithLoader('/api/checkout/return-paypal', {
      method: 'POST',
      body: JSON.stringify({
        FromMobile: false,
        PaypalToken,
        LanguageId,
        DiscountCode,
        IsGbmh,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const handlePaypalPaymentConfirmation = (paypalConfirmation: {
    IsGmbh: boolean
    LanguageId: number
    PaypalToken: string
  }) => {
    return fetchWithLoader('/api/checkout/payment-paypal-confirmation', {
      method: 'POST',
      body: JSON.stringify(paypalConfirmation),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const getAddressByPostCode = (postcode: string) => {
    return fetchWithLoader(`https://api.getAddress.io/v2/uk/${postcode}?api-key=yP6-zqoRkkKiu1HtBrbMGg6164`, {
      method: 'GET',
    })
  }

  const checkoutProceedPayment = (payload: any, service: string = '/pay') => {
    return fetchWithLoader(`/api/v1/${service}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  return {
    checkoutProceedPayment,
    getAddressByPostCode,
    getFailedData,
    getFinalFinanceOption,
    getSuccessData,
    handleCardReturn,
    handlePaypalPaymentConfirmation,
    handlePaypalReturn,
    handleSplititReturn,
    handleV12Return,
    initCheckoutData,
    payWithBankTransfer,
    payWithCard,
    payWithFinanceV12,
    payWithPaypal,
    payWithSplitit,
    saveOrderDetails,
  }
}
