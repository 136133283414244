// Move some composable from useShop here, not all of them should be composables

export const isPairDiamond = () => {
  const shopRoute = useShopRoute()
  // copied from legacy
  return (
    (shopRoute.query.diamondType || shopRoute.query.item) == -2 ||
    shopRoute.query.category == 5 ||
    shopRoute.query.subcategory == 15
  )
}
