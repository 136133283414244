import { generateTranslateKeyFromValue } from '~/utils/translate'

export const useTranslations = () => {
  const { t } = useI18n() // Access i18n instance
  const isProduction = process.env.NODE_ENV === 'production'

  // **Static Translate Function**
  const translate = (key, params = {}) => {
    if (!key) return '' // Handle empty or invalid keys
    return t(key, params) // Direct translation
  }

  // **Check if Key Exists**
  const handleDynamicKey = (key) => {
    // at the moment this check is enough can be extended later
    if (!key || typeof key !== 'string') {
      return '' // Return empty string if key is invalid
    }
    return key
  }

  // handle dynamic key, and update translation once i18n.messages is updated
  const computedTranslation = (key, ...options) => {
    return t(handleDynamicKey(key), ...options) // Return translation
  }

  const translateByValue = (labelValue: string) => {
    const kebabedLabel = generateTranslateKeyFromValue(labelValue)

    return t(`${kebabedLabel}`, labelValue)
  }

  const translateByAttributeId = (attributeId: string | number, fallbackLabel: string) => {
    const attribute = `attribute-id-${attributeId}`
    const fallback = isProduction ? fallbackLabel : 'f_' + fallbackLabel
    const translation = t(attribute, fallback)

    if (!isProduction && translation === fallback) {
      console.info('Using fallback translation:', attribute)
    }

    return translation
  }

  return {
    translate, // For static usage
    computedTranslation, // For dynamic usage
    handleDynamicKey,
    translateByValue,
    translateByAttributeId,
  }
}
