export function useNavigateWithLocale() {
  const localePath = useLocalePath()

  const navigateWithLocale = (path, external: boolean = false) => {
    const pathLocale = localePath(path)
    return navigateTo(pathLocale, { external: external })
  }

  return {
    navigateWithLocale,
  }
}
