export default () => {
  const config = useRuntimeConfig()

  return {
    ItemsPerPage: 50,
    NumberOfReviews: 4,
    Status: {
      ERROR: 0,
      SUCCESS: 1,
      ITEM_ALREADY_IN_BAG: 2,
      DIAMOND_NOT_SUPPORTED: 3,
      DIAMOND_NOT_AVAILABLE: 4,
    },
    MediaDomain: `${config.mediaPlayerUrl}/video`,
  }
}
