import { SOCIAL_MEDIA } from '~/constants/social'
import { useProductsStore } from '~/store/productsStore'
import useShopRouteStore from '~/store/shopRoute'
import { getSubCategory, getSubCategoryById } from '~/utils/shop/subCategories'
import { getMetalById } from './shop/metals'

export function openTalkToExperts(diamond) {
  // CHECK
  console.log(getPopupMessage(diamond))
}

export const getPopupMessage = (diamond, type) => {
  try {
    const { item } = useProductsStore()
    const { $i18n } = useNuxtApp()
    const shopRoute = useShopRouteStore()

    const query = shopRoute.query || {}
    const itemSettings = item.ItemSettings
    var shape = query.shape
    var subcat = $i18n.t(getSubCategoryById(query.subcategory).label)
    var url = window.location.href

    if (diamond) {
      var diamondInfo = ''
      if (!(diamond instanceof Array)) {
        var carat = diamond.Carat
        var color = diamond.StoneTypeID == '2' ? diamond.GemColorName : diamond.Color
        var clarity = diamond.StoneTypeID == '2' ? diamond.GemClarityName : diamond.Clarity
        shape = diamond.ShapeName
        diamondInfo =
          shape + ' ' + carat + $i18n.t('carat-abbreviation', 'ct') + ' ' + color + ' ' + clarity + ' - ' + diamond.Code
      } else {
        for (var d = 0; d < diamond.length; d++) {
          var carat = diamond[d].Carat
          var color = diamond[d].StoneTypeID == '2' ? diamond[d].GemColorName : diamond[d].Color
          var clarity = diamond[d].StoneTypeID == '2' ? diamond[d].GemClarityName : diamond[d].Clarity
          shape = diamond[d].ShapeName
          diamondInfo +=
            shape +
            ' ' +
            carat +
            $i18n.t('carat-abbreviation', 'ct') +
            ' ' +
            color +
            ' ' +
            clarity +
            ' - ' +
            diamond[d].Code +
            ', '
        }
        diamondInfo = trimRight(diamondInfo, ', ')
      }
    }

    var message = ''

    if (item || diamond) {
      switch (type) {
        case 'video':
          message += $i18n.t('would-like-to-request-video', 'I would like to request a video of the product below:\n\n')
          break
        default:
          message += $i18n.t(
            'would-like-to-have-more-information',
            'I would like to have more information about the product below:\n\n',
          )
      }

      if (item && diamond) {
        message += itemSettings.Name + ' (' + subcat + ') - '
        message = query.metal ? message + $i18n.t(getMetalById(query.metal).label) : message
        message += '\n'
        message += diamondInfo
      } else if (item) {
        message += itemSettings.Name + ' (' + subcat + ') - '
        message = query.metal ? message + $i18n.t(getMetalById(query.metal).label) : message
      } else if (diamond) {
        message += diamondInfo
      }

      message += '\n\n'
      message += url
    }

    return message
  } catch {
    return ''
  }
}

export const openChat2 = () => {
  if (typeof window.Kustomer?.open === 'function') {
    window.Kustomer.open()
  }
}

export function openwhatsapp() {
  window.open(SOCIAL_MEDIA.WHATSAPP.LINK, '_blank')
}

export function checkIfChatExists() {
  return !!document?.getElementById('liveHelp')
}
