import ringSizes from '@/data/ringSizes.json'
import { getAttributes } from '~/utils/shop/attributes.js'
import { useNewSettingsStore } from '~/store/newSettingsStore.ts'
import { CERTIFICATES } from '~/constants/gemstones'

const _widths = ringSizes.widths

const getSettingsStore = () => {
  return useNewSettingsStore()
}

export function findBy(obj, key, value) {
  if (isNaN(value)) {
    return obj.find((o) => o[key] == value)
  }
  return obj.find((o) => o[key] == value)
}

export function join(obj, field, separator, field2) {
  // _77T.T('round').toLowerCase()
  return obj
    .map((s) => (field2 ? [s[field2], s[field]] : s[field]))
    .flat()
    .filter((i) => i)
    .join(separator)
}

export const getValues = (obj) => {
  const _obj = {}
  Object.keys(obj)
    .filter((key) => obj[key] !== null && obj[key] !== undefined)
    .forEach((key) => {
      _obj[key] = obj[key]
    })
  return _obj
}

export function removeDuplicated(obj, key = 'Value') {
  return [...new Set(obj.map((size) => size[key]))].map((size) => obj.find((s) => s[key] == size))
}

export function removeDuplicatedComplex(obj, key = ['Value']) {
  return [...new Set(obj.map((size) => key.map((k) => size[k]).join('.')))].map((size) =>
    obj.find((s) => key.map((k) => s[k]).join('.') == size),
  )
}

export function loadScript(url, callback) {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  if (script.readyState) {
    // only required for IE <9
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

export function isGroupedShape(shape) {
  const { shopSettings } = getSettingsStore()
  shape = parseInt(shape)
  let ga = shopSettings?.Filters.NaturalDiamondsFilters.GroupedShapes.GroupedAttributes.some((g) =>
    g.Attributes.some((a) => a.Id == shape),
  )

  // return (this.opened[0].StoneTypeID == 1 && ga);
  return ga
}

export function getShapeGroupe(shape) {
  const { shopSettings } = getSettingsStore()
  shape = parseInt(shape)
  let ga = shopSettings?.Filters.NaturalDiamondsFilters.GroupedShapes.GroupedAttributes.find((g) =>
    g.Attributes.some((a) => a.Id == shape),
  )

  // return (this.opened[0].StoneTypeID == 1 && ga);
  return ga
}

export function getGemTypeName(gemType, doubleChar = false) {
  if (gemType == 366) return doubleChar ? 'RU' : 'R'
  else if (gemType == 367) return doubleChar ? 'SA' : 'S'
  else if (gemType == 371) return 'EM'
}

export function gemRealName(gemId) {
  switch (gemId) {
    case 367:
      return 'sapphire'
    case 371:
      return 'emerald'
    default:
      return 'ruby'
  }
}

export function isEcoCert(certId, stoneType) {
  var stone = parseInt(stoneType)
  if ((certId == CERTIFICATES.GIA && stone == 3) || (certId == CERTIFICATES.LGC && stone == 2)) {
    return true
  }

  return false
}

export function certifHasInfo(certId) {
  const infoCerts = [
    CERTIFICATES.ICL,
    CERTIFICATES.GCAL,
    CERTIFICATES.DF,
    CERTIFICATES.IGI,
    CERTIFICATES.HRD,
    CERTIFICATES.EGL,
    CERTIFICATES.GIA,
    CERTIFICATES.LGC,
  ]

  if (infoCerts.indexOf(certId) > -1) {
    return true
  }

  return false
}

export function ga4FilterReset(category = '', value) {
  try {
    let valueText = ''
    if (value) {
      if (value.includes(',')) {
        value = value.split(',')
        valueText = `${value[0]} to ${value[value.length - 1]}`
      } else if (value.length == 0) {
        valueText = ''
      } else {
        valueText = value
      }
    }
    window.DataLayer?.productListingFilterInteraction(category, valueText, 'Remove')
  } catch (error) {
    console.warn(`error calling datalayer.${category}`, value)
  }
}

export function ga4_productReviewInteraction(action) {
  try {
    dataLayerPush({
      event: 'productReviewInteraction',
      productReview: {
        action: action,
      },
    })
  } catch (error) {
    console.warn(`error calling datalayer.ga4_productReviewInteraction`, action)
  }
}

export function ga4_virtualRingStylerInteractions(action, selection) {
  try {
    dataLayerPush({
      event: 'virtualRingStylerInteractions',
      virtualRingStyler: {
        action: action,
        selection: selection,
      },
    })
  } catch (error) {
    console.warn(`error calling datalayer.ga4_virtualRingStylerInteractions`, action, selection)
  }
}

export function dataLayerPush(data) {
  try {
    if (window.DataLayer) {
      window.DataLayer.push(data)
    }
  } catch (error) {
    console.warn(`error calling datalayer.dataLayerPush`, data)
  }
}

export function microsoftTagsPush(eventName, eventValue) {
  try {
    if (eventName) {
      window.uetq = window.uetq || []
      window.uetq.push('event', eventName, eventValue || {})
    }
  } catch (error) {
    console.warn(`error calling uetq.push`, eventName, eventValue)
  }
}

export function imageLoadError(e) {
  console.warn('imageLoadError', e)

  if (e?.target) {
    e.target.src = new URL('~/assets/images/no_image_placeholder.png', import.meta.url).href
  }
}

export function hasDeko(orderValue = 0) {
  const { shopSettings, currentCountry, currentCurrency } = getSettingsStore()
  // condition copied from legacy
  return currentCountry.Id == '826' && currentCurrency.Id == '1' && orderValue < shopSettings?.Finance?.MaxLendValue
}

export function stoneTypeLabel(stonetype) {
  if (stonetype == 1) return 'diamond'
  else if (stonetype == 3) return 'lab-grown-diamond'
  else if (stonetype == 2) return 'gemstone'
  return null
}

export function isPromoBanner() {
  let searchParams = new URLSearchParams(window?.location.search)

  if (searchParams.has('rtcampaign')) {
    let param = searchParams.get('rtcampaign')

    if (param == 'promo') {
      sessionStorage.setItem('rtcampaign', param)
      return true
    }
  } else if (sessionStorage.getItem('rtcampaign') != null) {
    return true
  } else {
    return false
  }
}

export function formatTodaysDate() {
  const d = new Date()

  const month = d.getMonth() + 1
  const day = d.getDate()
  const h = d.getHours(),
    m = d.getMinutes()

  let output = d.getFullYear() + '/' + (month < 10 ? '0' : '') + month + '/' + (day < 10 ? '0' : '') + day + ' '
  output += h > 12 ? h + ':' + m + ' PM' : h + ':' + m + ' AM'

  return output
}

export function hideQuickShipping() {
  const currentDate = formatTodaysDate()
  const selectedDate = '2021-01-04T00:00:00.000Z'

  if (Date.parse(currentDate) <= Date.parse(selectedDate)) {
    return false
  } else {
    return true
  }
}

export function compare(a, b) {
  return JSON.stringify(a) === JSON.stringify(b)
}

export function getDepths() {
  return getAttributes('depth')
}

export function getWidths() {
  return _widths
}

export function openBespoke(diamond) {
  // CHECK
  // TODO: add form
  return ''
  let message = 'Type: ' + diamond.StoneTypeName + '\nCode: ' + diamond.Code + '\nShape: ' + diamond.ShapeName

  let form = `
            <form action="${_77T.L('/NewApp/Default.aspx?app=bespoke')}" method="POST" target="_blank">
                <input type="hidden" name="message" value="${message}">
            </form>
        `
  $(form).appendTo('body').submit()
}
