const redirectList = reactive({}) // Reactive object to store redirects
const startAppTimestamp = Date.now()

// Function to fetch the latest redirect rules
async function fetchLatestRedirects() {
  try {
    // const data = await $fetch('/api/redirects', { params: { timestamp: startAppTimestamp } })
    const data = [
      // {
      //   locale: 'en',
      //   from: '/engagement-rings/ring-size-chart',
      //   to: '/bespoke-jewellery',
      //   statusCode: 301,
      // },
    ]
    for (const item of data) {
      redirectList[item.from] = item.to // Assuming the API returns an array of objects with 'from' and 'to' properties
    }
  } catch (error) {
    console.error('Failed to fetch redirects:', error)
  }
}

// Initial fetch
fetchLatestRedirects()
// Periodic update every 5 minutes
// setInterval(() => fetchLatestRedirects(), 5 * 60 * 1000)

export default defineNuxtPlugin(async (nuxtApp) => {
  // Router beforeEach guard
  nuxtApp.vueApp.config.globalProperties.$router.beforeEach((to, from, next) => {
    if (redirectList[to.path]) {
      next({ path: redirectList[to.path], redirectedFrom: to.fullPath })
    } else {
      next()
    }
  })
})
