import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useRouteStore } from '~/store/routesStore'

type PageMetadata = {
  H1: string
  H2: string
  H3: string
  Title: string
}

const defaultValue: PageMetadata = {
  H1: '',
  H2: '',
  H3: '',
  Title: '',
}

const loadPageMetadata = () => {
  const routeStore = useRouteStore()
  const newSettingsStore = useNewSettingsStore()

  const { currentPageId } = storeToRefs(routeStore)
  const { currentLanguageId, currentCountryId } = storeToRefs(newSettingsStore)

  return useAsyncData(
    'page-metadata',
    async () => {
      if (!currentPageId.value) return

      return await $fetch('/api/v1/seo/page-metadata', {
        query: {
          pageId: currentPageId.value,
          languageId: currentLanguageId.value,
          countryId: currentCountryId.value?.toString(),
        },
      })
    },
    {
      watch: [() => currentPageId.value, () => currentLanguageId.value, () => currentCountryId.value],
    },
  )
}

export const usePageMetadata = () => {
  const { data: pageMetadataResponse } = loadPageMetadata()
  const metadata = computed<PageMetadata>(() => pageMetadataResponse.value || defaultValue)

  useHead({
    title: () => metadata.value.Title,
    meta: [
      {
        name: 'title',
        content: () => metadata.value.Title,
      },
    ],
  })

  return metadata
}
