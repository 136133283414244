import getGrafanaFaro from '~/helpers/grafana'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    if (!window.faro)
      try {
        getGrafanaFaro()
      } catch (e) {
        console.error('Error in getGrafanaFaro function: Faro script failed to load.', e)
      }
  })
})
