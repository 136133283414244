import { getItems } from '~/utils/shop/items.js'
import { getGemGroup } from '~/utils/shop/gems.js'
import { getSubCategoryIdByValue } from '~/utils/shop/subCategories.js'
import { getExpressSettings, getNaturalDiamondsFilters } from '~/utils/settingsUtils.js'
import { getCategory as _getCategory, getCategoryIdByValue } from '~/utils/shop/categories.js'
import { fetchFromNewApi } from '~/helpers/api.ts'
import { getDiamondsAndItemsCode } from '~/helpers/shoppingBag'
import { useLoaderStore } from '~/store/loaderStore'
import { useShoppingBagStore } from '~/store/shoppingBagStore'
import { useNewSettingsStore } from '~/store/newSettingsStore.ts'
import useShopRouteStore from '~/store/shopRoute'

import { SHOP_ROUTES_NAMES } from '~/constants/index'
import { CATEGORIES_ENUM } from '~/constants/categories'
import { STONE_TYPES } from '~/constants/stones'
import { GEM_TYPES } from '~/constants/types'
import { METALS } from '~/constants/metals'
import { EUROPEAN_COUNTRIES } from '~/constants/countries'

const getSettingsStore = () => {
  return useNewSettingsStore()
}

// util methods
const getValues = (obj) => {
  const _obj = {}
  Object.keys(obj)
    .filter((key) => obj[key] !== null && obj[key] !== undefined)
    .forEach((key) => {
      _obj[key] = obj[key]
    })
  return _obj
}

function _getItem({ category, subcategory, id, label }) {
  if (typeof category === 'string' && isNaN(category)) {
    category = getCategoryIdByValue(category)
  }
  if (typeof subcategory === 'string' && isNaN(subcategory)) {
    subcategory = getSubCategoryIdByValue(subcategory)
  }

  let items = getItems().filter((item) => (!id || item.id === id) && (!label || item.label == label))

  if (items.length > 1) {
    items = items.filter((item) => item.subcategory == subcategory)
  }

  return items[0]
}

function toArray(object, parser = null) {
  if (!object) return []
  if (object instanceof Array) return object
  if (typeof object == 'string') {
    return object.split(',').map(function (item) {
      return !parser ? item.trim() : parser(item)
    })
  }
  return [object]
}

function expressParams({ query }) {
  return {
    minCarat: query['min-carat'] && query.category == CATEGORIES_ENUM.ENGAGEMENT_RINGS ? query['min-carat'] : null,
    maxCarat: query['max-carat'] && query.category == CATEGORIES_ENUM.ENGAGEMENT_RINGS ? query['max-carat'] : null,
    ColorId:
      query.colors && query.colors.length && query.category == CATEGORIES_ENUM.ENGAGEMENT_RINGS ? query.colors : null,
    ClarityId:
      query.clarity && query.clarity.length && query.category == CATEGORIES_ENUM.ENGAGEMENT_RINGS
        ? query.clarity
        : null,
    RingSizeName: query['ring-size'],
    RingTypes: query['ring-type'] ? query['ring-type'].split(',') : undefined,
    metals: query.metals && toArray(query.metals).length ? toArray(query.metals) : [],
    shapes: query.shapes && toArray(query.shapes).length ? toArray(query.shapes) : [],
    currentPage: query.page || 1,
    WithStockImages: !!query['with-images'],
    itemsPerPage: query.resultsPerPage || getExpressSettings().ItemsPerPage,
  }
}

export async function getCategory(category) {
  return _getCategory(category)
}

function returnDiamondsCode(diamond) {
  return diamond ? toArray(diamond.toString().split(',')) : null
}

export async function getCategoryOptions({ category, query, preventUrlChange }, isCategoryPage = false) {
  // debugger
  // new API is not ready yet
  const api = query.isExpressShop ? 'express/filters' : 'category-filter-options'
  const params = query.isExpressShop
    ? expressParams({ query })
    : {
        quickShipping: !!query.quickShipping,
        fancyColorId: query.fancyColorId,
        ringSizeName: query.ringSizeName,
        diamondType: query.diamondType || null,
      }

  let config = api == 'category-filter-options' && isCategoryPage ? { hiddenLoader: true } : {}

  try {
    let response = await fetchFromNewApi(`/api/shop/${api}`, {
      method: 'POST',
      body: getValues({
        category,
        subCategories: toArray(query.subcategories),
        metals: toArray(query.metal),
        stoneType: query.stoneType || null,
        gemType: query.gemType || (query.stoneType == 2 ? 366 : null),
        shapes: !query.diamond && query.shapes ? toArray(query.shapes) : null,
        diamonds: toArray(query.diamond),
        minPrice: query['min-price'],
        maxPrice: query['max-price'],
        SortBy: query['sort'],
        ...params,
      }),
      ...config,
      preventUrlChange,
    })

    return response
  } catch (e) {
    // throw error
    console.error(e)
  } finally {
  }
}

export async function getProducts({ category, query = {}, preventUrlChange = false }) {
  const { start: startLoading, end: endLoading } = useLoaderStore()

  const shoppingBagStore = useShoppingBagStore()
  const { diamondsInBag, expressItemsInBag } = getDiamondsAndItemsCode(shoppingBagStore.Items)

  startLoading()
  let api = query.isExpressShop ? 'express/items' : 'category-items'
  let params = query.isExpressShop
    ? expressParams({ query })
    : {
        quickShipping: !!query.quickShipping,
        ringSizeName: query.ringSizeName,
        selectedDiamonds: toArray(
          query.selectedDiamonds ? query.selectedDiamonds.map((d) => d.Code.trim()).join() : query.diamond,
        ),
        fancyColorId: query.fancyColorId,
      }

  try {
    let response = await fetchFromNewApi(`/api/shop/${api}`, {
      method: 'POST',
      body: getValues({
        category,
        subCategories: toArray(query.subcategories),
        metals: toArray(query.metal),
        stoneType: query.stoneType || null,
        StoneTypeId: query.stoneType || null,
        diamondType: query.diamondType || null,
        shapes: !query.diamond && query.shapes ? toArray(query.shapes) : null,
        gemType: query.gemType || (query.stoneType == STONE_TYPES.GEMSTONE ? GEM_TYPES.RUBY : null),
        GemTypeId: query.gemType || (query.stoneType == STONE_TYPES.GEMSTONE ? GEM_TYPES.RUBY : null),
        diamonds: toArray(query.diamond),
        minPrice: query['min-price'],
        maxPrice: query['max-price'],
        sortBy: query['sort'] || 'price-asc',
        DiamondsInBag: diamondsInBag,
        ExpressItemsInBag: expressItemsInBag,
        ...params,
      }),
      preventUrlChange,
    })

    return response
  } catch (e) {
    // throw error
    console.error(e)
  } finally {
    endLoading()
  }
}

export async function getItem({ category, subcategory, item, query, forceBandSettings, preventUrlChange }) {
  const isCategoryPage = useShopRouteStore().name === SHOP_ROUTES_NAMES.CATEGORY

  const { start: startLoading, end: endLoading } = useLoaderStore()

  // startLoading()
  const api = query.isExpressShop ? '/api/shop/express/item-details' : '/api/shop/item-details'
  let config = { preventUrlChange }
  if (api === '/api/shop/item-details' && isCategoryPage) {
    config = { ...config, hiddenLoader: true }
  }

  // Looks like apis on server side are not case-sensitive
  try {
    if (api === '/api/shop/item-details') {
      const response = await fetchFromNewApi(api, {
        method: 'POST',
        body: {
          ...getItemParams({ category, subcategory, item, query, forceBandSettings }),
          fancyColorId: query.fancyColorId, // maybe not needed
          ringSize: query.ringSize || null,
          // ReviewsPerPage: window._77Settings.Shop.NumberOfReviews,
          ReviewsPerPage: 4,
          DiscountCode: query['discount-code'],

          EngravingItemsInBag: 0,
          DiamondsInBag: [],
          ExpressItemsInBag: [],

          Url: window?.location.href,
        },
        ...config,
      })

      return response
    } else {
      const response = await fetchFromNewApi(api, {
        method: 'POST',
        body: {
          ...getItemParamsLegacy({
            category,
            subcategory,
            item,
            query,
            forceBandSettings,
          }),
          fancyColorId: query.fancyColorId,
          ringSize: query.ringSize || null,
          reviewsPerPage: getSettingsStore().NumberOfReviews,
          'discount-code': query['discount-code'],
        },
        ...config,
      })

      return response
    }
  } catch (e) {
    // throw error
    console.error(e)
  } finally {
    endLoading()
  }
}

export const getVat = async () => {
  const { currentCountry } = getSettingsStore()
  const response = $fetch(`/api/v1/logistics/country-duty-tax`, {
    method: 'GET',
    query: { countryId: currentCountry.Id },
  })
  const tax = response?.d?.LooseTax || 0
  return tax ? (tax * 100).toString() : '0'
}
export const getFinanceOption = async ({ amount }) => {
  const response = await fetchFromNewApi(`/api/v1/payment/finance-option`, {
    method: 'GET',
    // body: { amount },
    params: {
      Amount: amount,
    },
  })
  // debugger;
  return response
}

export async function addToBasket({ category, subcategory, item, query }) {
  try {
    return await fetchFromNewApi('/api/v1/bag/add', {
      method: 'POST',
      body: {
        ...getItemParams({ category, subcategory, item, query }),
        stockNumbers: (query.stockNumber && [query.stockNumber]) || undefined,
        discountCode: query['discount-code'],
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export async function removeFromBasket(id) {
  try {
    return await fetchFromNewApi('/api/v1/bag/remove', {
      method: 'POST',
      body: { uniqueCode: id },
    })
  } catch (e) {
    console.error(e)
  }
}

export async function addDiamondToShoppingBag({ subcategory, item, shape, diamonds }) {
  try {
    // no new API yet
    // let response = await fetchFromSoap(`/WebService.asmx/AddToBasket`, {
    let response = await fetchFromNewApi('/api/v1/bag/add', {
      method: 'POST',
      body: {
        itemId: item,
        subcategoryId: subcategory,
        shapeId: shape,
        selectedDiamonds: toArray(diamonds),
      },
      headers: { stringify: 'json' },
    })

    return response
  } catch (e) {
    // throw error
    console.error(e)
  }
}

function getItemParamsLegacy({ category, subcategory, item, query, forceBandSettings }) {
  let _item
  if (isNaN(item)) _item = _getItem({ category, subcategory, label: item })
  else
    _item = {
      id: item,
      subcategory,
    }
  if (query.sizeNameAttributeId) {
    let sizeNameAttributeId = query.sizeNameAttributeId.split(',')
    query.sizeName = sizeNameAttributeId[0]
    query.attributeId = sizeNameAttributeId[1]
    query.size = query.size || sizeNameAttributeId[1]
  }

  return {
    item: _item.id,
    SubCategoryId: _item.subcategory,
    MetalId: parseInt(query.metal) || METALS.WHITE_GOLD,
    StoneType: query.stoneType ? query.stoneType : null,
    StoneTypeId: query.stoneType ? query.stoneType : null,
    diamondType: query.diamondType ? query.diamondType : null,
    shape: query.shape || (query.shapes && query.shapes[0]),
    SelectedDiamonds:
      query.selectedDiamonds && query.selectedDiamonds.length > 0
        ? toArray(query.selectedDiamonds.map((d) => d.Code.trim()).join())
        : returnDiamondsCode(query.diamond),

    componentsAndVariations:
      query.component || query.variation
        ? {
            componentSetId: query.componentSet,
            componentId: query.component,
            variationId: query.variation,
          }
        : null,

    bandSettings:
      forceBandSettings || query.depth || query.width || query.size || query.sizeName || query.ringSize
        ? {
            DepthId: query.depth,
            WidthId: query.width,
            RingSizeAttributeId: query.attributeId,
            CountryId: query.country || EUROPEAN_COUNTRIES.UNITED_KINGDOM.id,
            RingSizeIndex: query.size,
            RingSizeName: query.ringSize ? query.ringSize : query.sizeName,
          }
        : null,

    SizeName: query.sizeName,

    //bandSettings: query.country ? {country: query.country} : null,

    gemType: query.gemType,
    GemTypeId: query.gemType,

    StockNumbers: toArray(query.stockNumber),
    quickShipping: category == 4 || category == 1 ? !!query.quickShipping : false,

    engraving: query.engraving
      ? {
          description: query.engraving,
          fontOptionId: query.engraving_font,
        }
      : null,
  }
}

let lastDiamondParams = null

export async function getDiamonds({ category, subcategory, item, query = [], page = 1 }, cb) {
  // In this section, most of the declared values begin with an uppercase letter.
  // This is done intentionally to ensure that we override the corresponding keys in `diamondPreferences`,
  // which themselves use uppercase params. This way, the code correctly matches the expected structure
  // and processes the data as intended.

  const { diamondPreferences } = getSettingsStore()
  let shapes = toArray(query.shapes, parseInt)
  let gemClarity = toArray(query.gemClarity)
  let gemTreatment = toArray(query.gemTreatment)
  let gemClarityTreatment = toArray(query.gemClarityTreatment)
  const { name: routeName } = useShopRouteStore()

  if (query.stoneType == 2) {
    try {
      gemClarity = getGemGroup('Clarities', gemClarity)
      gemTreatment = getGemGroup('Treatments', gemTreatment)
      gemClarityTreatment = getGemGroup('ClarityTreatments', gemClarityTreatment)
    } catch (e) {}
  }

  if (query.selectedDiamond && query.selectedDiamond.indexOf(',') > -1) {
    query.selectedDiamond = query.selectedDiamond.split(',').map(function (item) {
      return item.trim()
    })
  }

  let stoneType = parseInt(query.stoneType) || 1

  // check for grouped shapes
  let ga =
    shapes.length == 1 && getNaturalDiamondsFilters().GroupedShapes.GroupedAttributes.some((g) => g.Id == shapes[0])
  let isGroupedShapes = stoneType == 1 && ga

  const body = {
    ...getValues({
      ...diamondPreferences,

      categoryId: query.category,
      itemId: item || -1,
      stoneType,
      diamondType: query.diamondType || -1,
      metalId: parseInt(query.metal) || METALS.WHITE_GOLD,
      quickShipping: query.category == 4 || query.category == 1 ? !!query.quickShipping : false,
    }),

    ...(query.selectedDiamond
      ? {
          selectedDiamonds: toArray(query.selectedDiamond),
          shapes: [],
        }
      : getValues({
          shapes,
          isGroupedShapes,
          CurrentPageNumber: page,
          resultsPerPage: query.resultsPerPage,
          colors: (query.colors && toArray(query.colors)) || [],
          intensities: toArray(query.intensity),
          clarities: toArray(query.clarity),
          certificates: toArray(query.certificate),
          cuts: isGroupedShapes ? [] : toArray(query.cut).filter((x) => x), // filter out empty values
          polishes: toArray(query.polish),
          symmetries: toArray(query.symmetry),
          fluorescences: toArray(query.fluorescence),
          minCarat: query['min-carat'],
          maxCarat: query['max-carat'],
          minPrice: query['min-price'],
          maxPrice: query['max-price'],
          minRatio: query['min-ratio'],
          maxRatio: query['max-ratio'],

          minDepth: query['min-depth'],
          maxDepth: query['max-depth'],
          minTable: query['min-table'],
          maxTable: query['max-table'],

          gemType: stoneType == 2 ? parseInt(query.gemType) || GEM_TYPES.RUBY : null,
          gemClarity,
          gemIntensity: toArray(query.gemIntensity),
          gemTreatment,
          gemClarityTreatment,
          gemOrigin: query.gemOrigin && toArray(query.gemOrigin),
          withMedia: !!query.withMedia,
          quickShipping: !!query.quickShipping,
        })),
  }

  if (lastDiamondParams != JSON.stringify(body)) {
    lastDiamondParams = JSON.stringify(body)

    return fetchFromNewApi(
      '/api/shop/diamond-list',
      {
        method: 'POST',
        body: body,
      },
      Boolean(routeName.includes('diamonds')),
    )
  }

  return null
}

export async function getDiamondsByIds(query, isCategoryPage = false) {
  let config = { preventUrlChange: query.preventUrlChange }
  if (isCategoryPage) {
    config.hiddenLoader = true
  }

  try {
    let response = await fetchFromNewApi(`/api/shop/diamond-list`, {
      method: 'POST',
      body: {
        selectedDiamonds: toArray(query.diamond.split(',')),
        shapes: toArray(query.shapes),
        colors: toArray(query.colors),
        clarities: toArray(query.clarity),
        quickShipping:
          query.category == 4 || query.category == 1 || query.category == 7 ? !!query.quickShipping : false,
        searchBlocked: query.searchBlocked || query.isExpressShop,
      },
      ...config,
    })

    return response
  } catch (e) {
    // alert(e)
    console.error(e)
  }
}

export async function getReviews({ item, reviewsPage, sort }) {
  try {
    const { currentLanguage } = getSettingsStore()
    let response = await $fetch(`/api/v1/product/reviews`, {
      method: 'POST',
      body: {
        LanguageId: currentLanguage.Id,
        ItemId: item,
        ReviewsPerPage: getSettingsStore().NumberOfReviews,
        ReviewsPage: reviewsPage,
        OrderBy: sort,
      },
    })

    return {
      Reviews: response.Reviews,
      NumberOfReviews: response.NumberOfReviews,
    }
  } catch (e) {
    // throw error
    console.error(e)
  }
}

export async function fetchServiceReviews({ languageId, reviewsPerPage, reviewsPage, orderBy }) {
  try {
    const response = await $fetch(`/api/v1/product/service-reviews`, {
      method: 'POST',
      body: {
        LanguageId: languageId,
        ReviewsPerPage: reviewsPerPage,
        ReviewsPage: reviewsPage,
        OrderBy: orderBy,
      },
    })

    return response || null
  } catch (error) {
    console.error(error)
    throw error // Rethrow to handle in the calling function
  }
}
