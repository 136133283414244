interface ProductConfig {}

type Product = {
  id: number
  name: string
}

const useShopStore = defineStore('shop', {
  state: () => ({
    query: {},
    params: {},
  }),

  actions: {
    async productsByConfig(configurations: ProductConfig): Promise<Product[]> {
      try {
        const { data, error } = await useFetch<{ Products: Product[] }>('/api/shop/products-by-config', {
          method: 'POST',
          body: configurations,
          headers: {},
        })

        if (error.value) {
          throw new Error('Failed to fetch products')
        }

        return data.value?.Products || []
      } catch (error) {
        console.error('Error fetching products by config:', error)
        throw error
      }
    },
  },
})

export default useShopStore
