import { useNewSettingsStore } from '~/store/newSettingsStore'

export const stripBaseUrl = (path) => {
  return path.replace(/^https?:\/\/[^#?\/]+/, '')
}

export const getCleanUrlSearchParams = (query) => {
  const searchParams = new URLSearchParams(query)
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    if (!value || value === 'undefined') {
      searchParams.delete(key)
    }
  })

  return searchParams
}

export const normalizeUrlInLanguages = (UrlInLanguages) =>
  UrlInLanguages?.map((x) => {
    const newUrl = new URL(x.Url)

    return {
      ...x,
      Url: window?.location.origin + newUrl.pathname + window?.location.search,
    }
  })

export const getCurrentUrlWithLocale = () => {
  const newSettingsStore = useNewSettingsStore()
  const url = new URL(window.location.href)
  const urlOrigin = url.origin

  if (newSettingsStore.currentLanguageCodeExcludeEnglish) {
    return `${urlOrigin}/${newSettingsStore.currentLanguageCodeExcludeEnglish}`
  }

  return urlOrigin
}
