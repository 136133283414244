import { useNewSettingsStore } from '~/store/newSettingsStore'
import useOrderStore from '~/store/order'
import useFinanceStore from '~/store/finance'
import { useNavigateWithLocale } from '~/composables/useNavigateWithLocale'

import { salutations } from '@/constants/utilConstants'
import { POST_PAYMENT_PAGES_ROUTES, PUBLIC_PAGES_ROUTES } from '~/constants/routes'
import { ORDER_TYPES } from '~/constants/order'
import { EU_COUNTRIES_ID } from '~/constants/countries'
import { REVALIDATE_DATA_FLAG } from '~/constants/paypal'

export enum CheckoutSteps {
  Checkout,
  Details,
  Payment,
}

interface AddressData {
  Address1: string
  Address2: string
  ApprovedById: number | null
  ApprovedMethodId: number | null
  ApprovedOn: string
  ApprovedWhy: string
  BusinessName: string
  City: string
  CountryId: number
  County: string
  CustomerId: number
  Id: number
  IsActive: boolean
  IsApproved: boolean
  IsBilling: boolean
  IsDelivery: boolean
  RecordDetails: {
    IsArchived: boolean
    RecordInsertedById: number
    RecordInsertedOn: string
    RecordModifiedById: number
    RecordModifiedOn: string
    UserName: string | null
  }
  PostCode: string
}

export const useCheckoutStore = defineStore('checkout', () => {
  const {
    getAddressByPostCode,
    getFailedData,
    getFinalFinanceOption,
    getSuccessData,
    initCheckoutData,
    payWithBankTransfer,
    payWithCard,
    payWithFinanceV12,
    payWithPaypal,
    payWithSplitit,
    saveOrderDetails,
  } = useCheckout()
  const { navigateWithLocale } = useNavigateWithLocale()
  const orderStore = useOrderStore()
  const financeStore = useFinanceStore()
  const { currentCountry, currentCurrency, currentLanguage, userPreferences } = storeToRefs(useNewSettingsStore())
  const checkoutStep = ref(CheckoutSteps.Checkout)

  const paymentError = ref([])
  const user = ref({
    Title: 1,
    FirstName: null,
    LastName: null,
    Gender: '',
    Email: null,
    PhoneCode: null,
    PhoneNumber: null,
    MarketingOption: false,
    DeliveryOption: 'BillingAddress',
    CollectionPlace: null,
    BillingAddress: {
      PostCode: '',
      Address1: null,
      Address2: '',
      City: '',
      County: '',
      CountryId: currentCountry.value?.Id || null,
    },
    DeliveryAddress: {
      PostCode: '',
      Address1: null,
      Address2: '',
      City: '',
      County: '',
      CountryId: currentCountry.value?.Id || null,
    },
    FinanceProduct: null,
    PaymentMethod: 1,
  })
  const addresses: Ref<{
    BillingAddress: string[]
    DeliveryAddress: string[]
  }> = ref({
    BillingAddress: [],
    DeliveryAddress: [],
  })
  const billingCountries = ref(null)
  const deliveryCountries = ref(null)
  const states = ref(null)
  const Items = ref(null)
  const OrderPrice = ref(null)
  const IncludeVat = ref(null)
  const discountCode = ref('') // TODO: set discount code
  const discountError = ref(false)
  const salutationsList = computed(() => {
    const { currentLanguage } = useNewSettingsStore()
    const currentSalutations = salutations.filter((s) => s.languageId == currentLanguage)
    if (!currentSalutations || currentSalutations.length == 0) return salutations.filter((s) => s.languageId == 1)
    return currentSalutations
  })
  const checkoutData = ref(null)
  const bannedCountry = ref(null)
  const EnumOrderType = ref({
    OnlineCreditCard: 1,
    OnlinePayPal: 2,
    OnlineGoogleCheckOut: 3,
    Phone: 4,
    BankTransfer: 5,
    Manual: 6,
    OnlineFinance: 7,
    Resize: 9,
    Repair: 10,
    Amazon: 11,
    BuyBack: 12,
    Return: 13,
    ManualOnsite: 14,
    ManualEmail: 15,
    SplitIt: 17,
    ApplePrimer: 18,
    VoltPrimer: 19,
  })
  const askForGender = ref(null)
  const financeOption = ref({})
  const financeProducts = ref([])
  const applePrimerAvailable = ref(false)
  const voltPrimerAvailable = ref(false)
  const loggedUser = ref(null)

  //todo implement only when payment has been implemented
  // const isStripeOrderConfirmation = CONFIRMATION_ORDER_PARAMETERS.stripe
  const isStripeOrderConfirmation = false
  const isAmazonOrderConfirmation = false

  // Getters
  const prepareCheckoutData = computed(() => {
    const model = user.value

    if (!model.PaymentMethod) {
      if (paymentAvailable.value.OnlineCreditCard) {
        model.PaymentMethod = EnumOrderType.value.OnlineCreditCard
      } else if (paymentAvailable.value.OnlinePayPal) {
        model.PaymentMethod = EnumOrderType.value.OnlinePayPal
      } else {
        model.PaymentMethod = EnumOrderType.value.OnlineGoogleCheckOut
      }
    }

    let financeChosenProduct = null

    if (user.value.PaymentMethod == EnumOrderType.value.OnlineFinance) {
      const product = financeProducts.value.find((p) => p.ProductId == user.value.FinanceProduct)
      financeChosenProduct = product?.ProductGuid
    }

    const DeliveryAddress = {
      PostCode: model.DeliveryAddress.PostCode,
      Address1: model.DeliveryAddress.Address1,
      Address2: model.DeliveryAddress.Address2,
      City: model.DeliveryAddress.City,
      County: model.DeliveryAddress.County,
      CountryId: model.DeliveryAddress.CountryId,
    }

    const BillingAddress = {
      PostCode: model.BillingAddress.PostCode,
      Address1: model.BillingAddress.Address1,
      Address2: model.BillingAddress.Address2,
      City: model.BillingAddress.City,
      County: model.BillingAddress.County,
      CountryId: model.BillingAddress.CountryId,
    }

    const BlankAddress = {
      PostCode: '',
      Address1: '',
      Address2: '',
      City: '',
      County: '',
      CountryId: -1,
    }

    return {
      checkoutStep: checkoutStep.value,
      model: {
        ...model,
        BillingAddress: model.DeliveryOption == 'BillingAddress' ? DeliveryAddress : BillingAddress,
        DeliveryAddress: model.DeliveryOption == 'BillingAddress' ? BlankAddress : DeliveryAddress,
      },
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: discountCode.value,
      },
      financeChosenProduct,
    }
  })

  const paymentAvailable = computed(() => {
    const pam = checkoutData.value?.PaymentMethodsAvailability

    return {
      OnlineCreditCard: !pam.CountriesWithoutCreditCardPaymentAvailable.includes(currentCountry.value?.Id),
      OnlinePayPal: !pam.CountriesWithoutPayPalPaymentAvailable.includes(currentCountry.value?.Id),
      OnlineGoogleCheckOut: true,
      Phone: false,
      BankTransfer: pam.IsBankPaymentAvailable,
      Manual: true,
      OnlineFinance: financeOption.value.FinanceEnabled && financeStore.v12financeProductsList.length > 0,
      Resize: true,
      Repair: true,
      Amazon: true,
      BuyBack: true,
      Return: true,
      ManualOnsite: true,
      ManualEmail: true,
      SplitIt: financeOption.value.SplitItEnabled,
      applePrimer: applePrimerAvailable.value,
      voltPrimer: voltPrimerAvailable.value,
    }
  })

  const userFullName = computed(() => `${user.value.FirstName} ${user.value.LastName}`)

  const currentPaymentMethod = computed(() => user.value?.PaymentMethod ?? null)

  const isCreditCardOrPaypalPayment = computed(() =>
    [ORDER_TYPES.OnlineCreditCard, ORDER_TYPES.OnlinePayPal].includes(currentPaymentMethod.value),
  )

  const isAmazonPayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.Amazon && isAmazonOrderConfirmation)

  const isBankTransferPayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.BankTransfer)

  const isKlarnaPayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.Klarna && isStripeOrderConfirmation)

  const isPhonePayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.Phone)

  const isStripePayment = computed(
    () => currentPaymentMethod.value === ORDER_TYPES.OnlineCreditCard && isStripeOrderConfirmation,
  )

  const v12SelectedProductId = computed(() => user.value?.FinanceProduct)

  const isCheckoutUserFilledFromStep1 = computed(() => !!user.value?.FirstName && !!user.value?.LastName)

  const isCheckoutUserFilledFromStep2 = computed(
    () => !!user.value?.BillingAddress?.PostCode && !!user.value?.BillingAddress?.Address1,
  )

  const userCountryDeliveryAddress = computed(() => Number(user.value?.DeliveryAddress.CountryId))

  const isFinanceV12Enabled = computed(
    () => paymentAvailable.value.OnlineFinance && userCountryDeliveryAddress.value === EU_COUNTRIES_ID.UnitedKingdom,
  )

  // Actions
  const setBag = (bagContent) => {
    Items.value = bagContent.Items
    OrderPrice.value = bagContent.OrderPrice
    IncludeVat.value = bagContent.IncludeVat

    gglTrack.UpdateCountry(bagContent.PageViewTracking.Country)
    gglTrack.UpdateCurrency(bagContent.PageViewTracking.Currency)
    gglTrack.BasketCheckoutPageView(
      bagContent.PageViewTracking.Category,
      bagContent.PageViewTracking.SubCategory,
      bagContent.PageViewTracking.Name,
      bagContent.PageViewTracking.Price,
      bagContent.OrderPrice.TotalPrice.FinalPrice.NumericPrice.WithVat,
    )
  }

  const setPaymentMethod = (paymentMethod) => {
    user.value.PaymentMethod = paymentMethod
  }
  const setAddresses = ({ type, list }: { type: 'BillingAddress' | 'DeliveryAddress'; list: string[] }) => {
    addresses.value[type] = list
  }

  const updateAddress = ({ type, address }) => {
    const addressArray = address.split(',')

    user.value[type].Address1 = addressArray
      .slice(0, 5)
      .filter((s) => s != ' ')
      .join()
    user.value[type].Address2 = addressArray[6]
    user.value[type].City = addressArray[5]
  }

  const setFinanceOption = (financeOptions) => {
    financeOption.value = financeOptions
  }

  const setFinanceProducts = (products) => {
    financeProducts.value = products

    if (products && products.length) {
      user.value.FinanceProduct = products[0].ProductId
    }
  }

  const setApplePrimerAvailability = (availability: boolean) => {
    applePrimerAvailable.value = availability
  }

  const setVoltPrimerAvailability = (availability) => {
    voltPrimerAvailable.value = availability
  }

  const setUser = (userObj) => {
    loggedUser.value = userObj

    if (userObj) {
      user.value.Title = userObj.title ? salutations.find((s) => s.label == userObj.title).value : 1
      user.value.FirstName = userObj.firstName
      user.value.LastName = userObj.lastName
      user.value.Email = userObj.email
      user.value.PhoneCode = userObj.phoneCode
      user.value.PhoneNumber = userObj.phoneNumber

      if (userObj.addresses) {
        const isDefaultDelivery = userObj.addresses.find((a) => a.isDefaultDelivery)
        const isDefaultBilling = userObj.addresses.find((a) => a.isDefaultBilling)

        if (isDefaultBilling)
          user.value.BillingAddress = {
            PostCode: isDefaultBilling.postCode,
            Address1: isDefaultBilling.addressLine1,
            Address2: isDefaultBilling.addressLine2,
            City: isDefaultBilling.city,
            County: isDefaultBilling.county,
            CountryId: isDefaultBilling.countryId,
          }

        if (isDefaultDelivery)
          user.value.DeliveryAddress = {
            PostCode: isDefaultDelivery.postCode,
            Address1: isDefaultDelivery.addressLine1,
            Address2: isDefaultDelivery.addressLine2,
            City: isDefaultDelivery.city,
            County: isDefaultDelivery.county,
            CountryId: isDefaultDelivery.countryId,
          }

        if (isDefaultDelivery && isDefaultBilling && isDefaultBilling.id === isDefaultDelivery.id)
          user.value.DeliveryOption = 'BillingAddress'
        else user.value.DeliveryOption = 'DeliveryAddress'
      }
    }
  }

  const setUserByRecoveryData = (recoveryData) => {
    user.value = recoveryData
  }

  const setUserV12Product = (productId: number) => {
    user.value.FinanceProduct = productId
  }

  const setCheckoutStep = (newStep: CheckoutSteps) => {
    checkoutStep.value = newStep
  }

  const setAddress = (addressId) => {
    if (!loggedUser.value || !loggedUser.value.addresses || !loggedUser.value.addresses.length || !addressId) return

    const address = loggedUser.value.addresses.find((a) => a.id === addressId)

    if (!address) return
    const isDefaultBilling = loggedUser.value.addresses.find((a) => a.isDefaultBilling)

    user.value.Title = address.title ? salutations.find((s) => s.label == address.title).value : 1
    user.value.FirstName = address.firstName
    user.value.LastName = address.lastName
    user.value.PhoneCode = address.phoneCode
    user.value.PhoneNumber = address.phoneNumber

    if (!address.isDefaultBilling || !address.isDefaultDelivery) {
      user.value.DeliveryOption = 'DeliveryAddress'

      user.value.DeliveryAddress = {
        PostCode: address.postCode,
        Address1: address.addressLine1,
        Address2: address.addressLine2,
        City: address.city,
        County: address.county,
        CountryId: address.countryId,
      }

      if (isDefaultBilling) {
        user.value.BillingAddress = {
          PostCode: isDefaultBilling.postCode,
          Address1: isDefaultBilling.addressLine1,
          Address2: isDefaultBilling.addressLine2,
          City: isDefaultBilling.city,
          County: isDefaultBilling.county,
          CountryId: isDefaultBilling.countryId,
        }
      }

      return
    } else if (address.isDefaultDelivery && address.isDefaultBilling) {
      const addressToSet = {
        PostCode: address.postCode,
        Address1: address.addressLine1,
        Address2: address.addressLine2,
        City: address.city,
        County: address.county,
        CountryId: address.countryId,
      }

      user.value.BillingAddress = addressToSet
      user.value.DeliveryAddress = addressToSet
      user.value.DeliveryOption = 'BillingAddress'
    }
  }

  const getFinanceOption = async () => {
    const response = await getFinalFinanceOption({
      countryId: currentCountry.value?.Id,
      currencyId: currentCurrency.value?.Id,
      languageId: currentLanguage.value?.Id,
      discountCode: discountCode.value,
    })

    setFinanceOption(response)
  }

  const saveDetails = async () => {
    checkoutStep.value = CheckoutSteps.Details
  }

  const getAddress = async (type) => {
    setAddresses({ type, list: [] })
    updateAddress({ type, address: '' })

    try {
      const result = await getAddressByPostCode(user.value[type].PostCode)

      if (result && (result.Addresses || []).length) {
        setAddresses({ type, list: result.Addresses })
        updateAddress({ type, address: result.Addresses[0] })
      } else {
        return false
      }

      return result
    } catch (e) {
      return {
        Addresses: [],
        Latitude: 0,
        Longitude: 0,
      }
    }
  }

  const saveAddress = async () => {
    await saveOrderDetails(prepareCheckoutData.value)
    checkoutStep.value = CheckoutSteps.Payment
  }

  const checkoutWithPaypal = async (url: string) => {
    try {
      setPaymentMethod(EnumOrderType.value.OnlinePayPal)

      const paypalReturnUrl = `${url}${POST_PAYMENT_PAGES_ROUTES.PAYPAL}`
      const paypalCancelUrl = `${url}${PUBLIC_PAGES_ROUTES.CHECKOUT}?${REVALIDATE_DATA_FLAG}=true`

      const { Url: paypalPaymentPage } = await payWithPaypal({
        paypalReturnUrl,
        paypalCancelUrl,
        model: prepareCheckoutData.value.model,
        userPreference: prepareCheckoutData.value.userPreference,
      })

      window.location.href = paypalPaymentPage
    } catch (err) {
      if (err.statusCode && err.data) {
        const { errors } = err.data

        paymentError.value = errors
      }
    }
  }

  const checkoutWithSplitit = async (url: string) => {
    window.location.href = await payWithSplitit({
      model: prepareCheckoutData.value.model,
      userPreference: prepareCheckoutData.value.userPreference,
      callbackUrl: `${url}${POST_PAYMENT_PAGES_ROUTES.SPLITIT}`,
    })
  }

  const checkoutWithV12 = async () => {
    const payload = {
      model: { ...prepareCheckoutData.value.model },
      financeProductGuid: financeStore.v12financeProductGuidById,
      userPreference: prepareCheckoutData.value.userPreference,
    }

    const { Url } = await payWithFinanceV12(payload)

    window.location.href = Url
  }

  const checkoutCardPayment = async (token, currentUrl) => {
    const redirect3dsUrl = `${currentUrl}${POST_PAYMENT_PAGES_ROUTES.CARD}`

    const payload = {
      model: { ...prepareCheckoutData.value.model },
      redirect3dsUrl,
      userPreference: prepareCheckoutData.value.userPreference,
      token,
    }

    return await payWithCard(payload)
  }

  const checkoutWithBankTransfer = async () => {
    const status = await payWithBankTransfer({
      model: prepareCheckoutData.value.model,
      userPreference: prepareCheckoutData.value.userPreference,
    })

    if (status === 'success') {
      navigateWithLocale(POST_PAYMENT_PAGES_ROUTES.SUCCESS)
    }
  }

  const prepareRecoveryCheckoutUserAddressData = (addressData: AddressData) => {
    return {
      Address1: addressData.Address1,
      Address2: addressData.Address2,
      City: addressData.City,
      CountryId: addressData.CountryId,
      County: addressData.County || '',
      PostCode: addressData.PostCode,
    }
  }

  const setUserBillingAddress = (newBillingAddressData: AddressData) => {
    const defaultInitialAddress = {
      Address1: null,
      Address2: '',
      City: '',
      CountryId: userPreferences.value?.CountryId || null,
      County: '',
      PostCode: '',
    }

    user.value = {
      ...user.value,
      BillingAddress: { ...defaultInitialAddress, ...newBillingAddressData },
    }
  }

  const copyDeliveryAddressToBillingAddress = () => {
    setUserBillingAddress(user.value.DeliveryAddress)
  }

  const hasValidAddressToRecovery = (addressData: AddressData): boolean =>
    !!addressData?.Id && !!addressData?.IsActive && !!addressData?.Address1 && !!addressData?.CountryId

  const handleRecoveryAddresses = (billingAddress: AddressData, deliveryAddress: AddressData) => {
    const defaultInitialAddress = {
      Address1: null,
      Address2: '',
      City: '',
      CountryId: userPreferences.value?.CountryId || null,
      County: '',
      PostCode: '',
    }

    const hasBillingAddressRecoveryData = hasValidAddressToRecovery(billingAddress)
    const hasDeliveryAddressRecoveryData = hasValidAddressToRecovery(deliveryAddress)

    const billingAddressData = hasBillingAddressRecoveryData
      ? prepareRecoveryCheckoutUserAddressData(billingAddress)
      : defaultInitialAddress

    const deliveryAddressData = hasDeliveryAddressRecoveryData
      ? prepareRecoveryCheckoutUserAddressData(deliveryAddress)
      : defaultInitialAddress

    if (billingAddressData?.Address1 && !deliveryAddressData?.Address1) {
      return {
        billingAddress: billingAddressData,
        deliveryAddress: billingAddressData,
      }
    }

    return {
      billingAddress: billingAddressData,
      deliveryAddress: deliveryAddressData,
    }
  }

  const prepareRecoveryCheckoutUserData = (recoveryData) => {
    const { billingAddress, deliveryAddress } = handleRecoveryAddresses(
      recoveryData.BillingAddress,
      recoveryData.DeliveryAddress,
    )

    return {
      ...user.value,
      ...recoveryData,
      BillingAddress: billingAddress,
      CollectionPlace: recoveryData.CollectionPlaceId,
      DeliveryAddress: deliveryAddress,
      PaymentMethod: recoveryData.PaymentMethodId,
    }
  }

  const setCheckoutStepsToPayment = () => {
    setCheckoutStep(CheckoutSteps.Payment)
  }

  const setCheckoutStepsToDetails = () => {
    setCheckoutStep(CheckoutSteps.Details)
  }

  const setCheckoutStepsToCheckout = () => {
    setCheckoutStep(CheckoutSteps.Checkout)
  }

  const handleSkipCheckoutSteps = () => {
    if (isCheckoutUserFilledFromStep2.value && isCheckoutUserFilledFromStep1.value) {
      setCheckoutStepsToPayment()
      return
    }

    if (isCheckoutUserFilledFromStep1.value) {
      setCheckoutStepsToDetails()
    }
  }

  const initCheckout = async () => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: discountCode.value,
      },
    }

    try {
      const response = await initCheckoutData(payload)

      if (!response.BillingCountries) return

      if (response.CheckoutCustomerDetails?.Email) {
        const recoveredUserData = prepareRecoveryCheckoutUserData(response.CheckoutCustomerDetails)

        setUserByRecoveryData(recoveredUserData)
        handleSkipCheckoutSteps()
      }

      checkoutData.value = response
      billingCountries.value = response.BillingCountries
      deliveryCountries.value = response.DeliveryCountries
      states.value = response.States
      Items.value = response.ShoppingBag.Items
      OrderPrice.value = response.ShoppingBag.OrderPrice
      IncludeVat.value = response.ShoppingBag.IncludeVat
    } catch (error) {
      if (error.status === 422 && error?.data?.data) {
        const MAX_ATTEMPTS_ERROR = 'tries.exceeded'
        const errors = error.data?.data?.errors
        const paymentExceededError = errors.find((item) => item?.Code === MAX_ATTEMPTS_ERROR)

        if (paymentExceededError) {
          navigateWithLocale(POST_PAYMENT_PAGES_ROUTES.FAILED)
        }
      }
    }
  }

  const initSuccess = async () => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: discountCode.value,
      },
    }

    const response = await getSuccessData(payload)

    if (response?.OrderId) {
      orderStore.setOrderData(response)
    }
  }

  const initFailed = async () => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: discountCode.value,
      },
    }

    const response = await getFailedData(payload)

    if (response?.OrderId) {
      orderStore.setFailedOrderData(response)
    }
  }

  return {
    checkoutStep,
    user,
    addresses,
    Items,
    OrderPrice,
    IncludeVat,
    discountCode,
    discountError,
    salutationsList,
    checkoutData,
    billingCountries,
    deliveryCountries,
    states,
    paymentError,
    bannedCountry,
    EnumOrderType,
    currentCountry,
    askForGender,
    financeOption,
    financeProducts,
    applePrimerAvailable,
    voltPrimerAvailable,
    loggedUser,
    prepareCheckoutData,
    paymentAvailable,
    userFullName,
    isCreditCardOrPaypalPayment,
    isAmazonPayment,
    isBankTransferPayment,
    isCheckoutUserFilledFromStep1,
    isCheckoutUserFilledFromStep2,
    isKlarnaPayment,
    isPhonePayment,
    isStripePayment,
    v12SelectedProductId,
    userCountryDeliveryAddress,
    isFinanceV12Enabled,
    setBag,
    checkoutCardPayment,
    checkoutWithBankTransfer,
    checkoutWithPaypal,
    checkoutWithSplitit,
    checkoutWithV12,
    getAddress,
    getFinanceOption,
    initCheckout,
    initFailed,
    initSuccess,
    saveAddress,
    saveDetails,
    setAddress,
    setAddresses,
    setApplePrimerAvailability,
    setCheckoutStep,
    setCheckoutStepsToPayment,
    setCheckoutStepsToDetails,
    setCheckoutStepsToCheckout,
    setFinanceOption,
    setFinanceProducts,
    setPaymentMethod,
    setUser,
    setUserV12Product,
    setVoltPrimerAvailability,
    updateAddress,
    setUserBillingAddress,
    copyDeliveryAddressToBillingAddress,
  }
})
