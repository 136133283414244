import { defineStore } from 'pinia'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useShoppingBagStore } from '~/store/shoppingBagStore'
import { useCheckoutStore } from '~/store/checkoutStore'
import { useProductsStore } from './productsStore'
import { useFinances } from '~/composables/useFinances'

interface V12FinancialDetail {
  Numeric: number
  Web: string
}

export interface V12Product {
  APR: V12FinancialDetail
  AmountPayable: V12FinancialDetail
  CashPrice: V12FinancialDetail
  CostOfLoan: V12FinancialDetail
  DepositAmount: V12FinancialDetail
  DepositPercent: V12FinancialDetail
  InterestRate: V12FinancialDetail
  LoanAmount: V12FinancialDetail
  LoanRepaid: V12FinancialDetail
  MonthlyPayment: V12FinancialDetail
  NumberOfMonthlyPayments: number
  ProductGuid: string
  ProductId: number
  ProductName: string
  TranslationShortName: string
}

export type V12ProductList = V12Product[]

const useFinanceStore = defineStore({
  id: 'finance',
  state: () => ({
    v12financeProductsOptions: [] as V12ProductList,
    productFinanceOptions: [] as unknown,
  }),
  getters: {
    v12financeProductsList(state): V12Product[] {
      return Object.values(state.v12financeProductsOptions)
    },
    v12LowestProductValuePerMonth(): string {
      const sortedProducts = this.v12financeProductsList.sort(
        (a, b) => a.MonthlyPayment.Numeric > b.MonthlyPayment.Numeric,
      )

      return sortedProducts[0]
    },
    v12financeProductById(): V12Product {
      const checkoutStore = useCheckoutStore()

      return this.v12financeProductsList.find((item) => item.ProductId === checkoutStore.v12SelectedProductId) || {}
    },
    v12financeProductGuidById(): string {
      const checkoutStore = useCheckoutStore()
      const foundProduct = this.v12financeProductsList.find(
        (item) => item.ProductId === checkoutStore.v12SelectedProductId,
      )

      return foundProduct.ProductGuid
    },
  },
  actions: {
    setDefaultV12UserProduct() {
      const products = Object.values(this.v12financeProductsOptions)
      const checkoutStore = useCheckoutStore()

      checkoutStore.setUserV12Product(products[0].ProductId)
    },
    async handleGetV12FinanceOptions() {
      const { currentCurrency } = useNewSettingsStore()
      const shoppingBagStore = useShoppingBagStore()
      const { getV12FinanceOptions } = useFinances()

      const response = await getV12FinanceOptions({
        Amount: shoppingBagStore.orderNumericPriceTotalWithVat,
        CurrencyId: currentCurrency.Id,
      })

      this.v12financeProductsOptions = { ...response }
      this.setDefaultV12UserProduct()
    },
    async handleGetProductFinanceOptions() {
      const { item } = useProductsStore()

      if (!item.ItemSettings?.OrderInfo) {
        return
      }

      const { getProductFinanceOption } = useFinances()
      const { currentCountry, currentCurrency } = useNewSettingsStore()
      const payload = {
        amount: item.ItemSettings.OrderInfo?.ItemPrice.TotalPrice.FinalPrice.NumericPrice.WithVat,
        countryId: currentCountry.Id,
        currencyId: currentCurrency.Id,
      }

      this.productFinanceOptions = await getProductFinanceOption(payload)
    },
    async calculateV12ProductFinanceOptions() {
      const { getV12FinanceOptions } = useFinances()

      const { item } = useProductsStore()
      const { currentCurrency } = useNewSettingsStore()

      const payload = {
        amount: item.ItemSettings.OrderInfo.ItemPrice.TotalPrice.FinalPrice.NumericPrice.WithVat,
        currencyId: currentCurrency.Id,
      }

      this.v12financeProductsOptions = await getV12FinanceOptions(payload)
    },
  },
})

export default useFinanceStore
