import { type Faro } from '@grafana/faro-web-sdk'

let FARO_INTANCE: Faro | null = null

async function initGrafana(): Promise<Faro> {
  const { initializeFaro, getWebInstrumentations } = await import('@grafana/faro-web-sdk')
  const { TracingInstrumentation } = await import('@grafana/faro-web-tracing')

  return new Promise((resolve, reject) => {
    try {
      const {
        public: { grafana },
      } = useRuntimeConfig()

      const faro = initializeFaro({
        url: grafana.url,
        app: {
          name: grafana.siteName,
          version: '1.0.0',
          environment: process.env.NODE_ENV,
        },
        sessionTracking: {
          samplingRate: 1,
        },
        instrumentations: [
          // Mandatory, omits default instrumentations otherwise.
          ...getWebInstrumentations(),
          // Tracing package to get end-to-end visibility for HTTP requests.
          new TracingInstrumentation(),
        ],
      })
      resolve(faro)
    } catch (e) {
      reject(e)
    }
  })
}

export default async function getGrafanaFaro() {
  if (FARO_INTANCE) {
    return FARO_INTANCE
  }

  const localfaroInstance = await initGrafana()
  if (!localfaroInstance) {
    throw new Error('Error trying to get Grafana Faro instance')
  }

  FARO_INTANCE = localfaroInstance

  return FARO_INTANCE
}
