interface DeliveryDates {
  max: string
  min: string
}

interface OrderState {
  deliveryDates: DeliveryDates
  dispatchDate: string | ''
  isCollection: boolean
  failedOrderData: Record<string, string | number>
  orderData: Record<string, string | number>
  preOrderData: Record<string, string | number>
  showroom: string
}

const useOrderStore = defineStore({
  id: 'order',
  state: (): OrderState => ({
    deliveryDates: { min: '', max: '' },
    dispatchDate: '',
    isCollection: false,
    failedOrderData: {},
    orderData: {},
    preOrderData: {},
    showroom: '',
  }),
  getters: {
    hasDeliveryDates(state): boolean {
      return Boolean(state.deliveryDates.min && state.deliveryDates.max)
    },
    bankAccountInfo(state): Record<string, string | number> {
      return state.orderData?.BankAccountInfo ?? {}
    },
    customerFullName(state): string {
      return `${state.orderData?.CustomerFirstName} ${state.orderData?.CustomerLastName}`
    },
    orderBag(state): Record<string, string | number | []> {
      return state.orderData?.ShoppingBag
    },
    orderItems(): Record<string, string | number | []> {
      return this.orderBag?.Items
    },
    failedOrderItems(state): Record<string, string | number | []> {
      return state.failedOrderData?.ShoppingBag?.Items
    },
    orderTotalWithVat(state): string {
      return state.orderData?.ShoppingBag?.OrderPrice?.TotalPrice?.FinalPrice?.WebPrice?.WithVat
    },
    preOrderItems(state): Record<string, string | number | []> {
      return state.preOrderData?.Items?.Items
    },
    preOrderTotalWithVat(state): string {
      return state.preOrderData?.Items?.OrderPrice?.TotalPrice?.FinalPrice?.WebPrice?.WithVat
    },
    failedOrderTotalWithVat(state): string {
      return state.failedOrderData?.ShoppingBag?.OrderPrice?.TotalPrice?.FinalPrice?.WebPrice?.WithVat
    },
  },
  actions: {
    resetPreOrderData() {
      this.preOrderData = {}
    },
    setDeliveryData({ Min, Max }: { Min?: string; Max?: string } = {}): void {
      if (Min && Max) {
        this.deliveryDates = { min: Min, max: Max }
        return
      }

      this.deliveryDates = { min: '', max: '' }
    },
    setDispatchDate(dispatchDate: string) {
      this.dispatchDate = dispatchDate ?? ''
    },
    setIsCollection(isCollection: boolean) {
      this.isCollection = isCollection
    },
    setOrderData(newData: Record<string, string | number>): void {
      this.orderData = newData

      this.setIsCollection(newData.IsCollection)

      if (newData?.DeliveryDeadline) {
        this.setDeliveryData(newData.DeliveryDeadline)
      }

      if (newData?.DispatchDeadline) {
        this.setDispatchDate(newData.DispatchDeadline)
      }
    },
    setPreOrderData(newData: Record<string, string | number>): void {
      this.preOrderData = newData
    },
    setFailedOrderData(newData: Record<string, string | number>): void {
      this.failedOrderData = newData
    },
  },
})

export default useOrderStore
