import type { RouteRecordRaw } from 'vue-router'

type RouteMeta = {
  path?: string
  aspParams?: [string, string][]
}
type UrlInLanguage = {
  Url: string
  LanguageId: number | string
  aspParams: [string, string][]
  path: string
}

type RouteRecordWithMeta = RouteRecordRaw & {
  aspRoute: string
}

export const useRouteStore = defineStore('routesStore', {
  state: () => ({
    staticEnRoutes: [] as RouteRecordWithMeta[],
    shopRoutes: [] as RouteMeta[],
    locale: null as string | null,
    currentRoute: null as RouteMeta | null,
    currentPageId: null as number | null,
    UrlInLanguages: [] as UrlInLanguage[],
  }),
  getters: {
    getStaticRouteByAspRoute: (state) => (aspRoute: string) => {
      return state.staticEnRoutes.find((route) => route.meta?.aspRoute === aspRoute)
    },
    getShopRouteByQueryParams: (state) => (queryParams: [string, string][]) => {
      const aspParamsObject = Object.fromEntries(queryParams)
      const matchedRoutes = state.shopRoutes.filter((route) =>
        route?.aspParams.every((r) => aspParamsObject[r.key] === r.value),
      )
      return matchedRoutes.sort((a, b) => b.length - a.length)[0]
    },
    getUrlLanguageByLocale: (state) => (locale: string) => {
      const nuxtApp = useNuxtApp()
      const locales = nuxtApp.$i18n.locales.value
      const localeI18n = locales.find((x) => x.code === locale)
      if (!localeI18n) return
      return state.UrlInLanguages.find((x) => x.LanguageId === localeI18n.id)
    },
    getUrlLanguageByPath: (state) => (path: string) => {
      return state.UrlInLanguages?.find((x) => x.path === path)
    },
  },

  actions: {
    initEnStaticRoutes() {
      if (!this.staticEnRoutes.length) {
        const router = useRouter()
        const allRoutes = router.getRoutes() as RouteRecordWithMeta[]
        this.staticEnRoutes = allRoutes.filter((route) => route.meta.locale === 'en')
      }
    },
    setShopRoutes(shopRoutes: RouteMeta[]) {
      this.shopRoutes = shopRoutes || []
    },
    addShopRoutes(shopRoutes: RouteMeta[]) {
      this.shopRoutes = [].concat(shopRoutes, this.shopRoutes)
    },
    setLocale(locale: string) {
      this.locale = locale
    },
    setCurrentRouteByPath(path: string) {
      this.currentRoute = this.shopRoutes.find((route) => route.path === path) || null
    },
    setCurrentRouteByPageId(pageId: string | number) {
      const stringPageId = typeof pageId === 'string' ? pageId : pageId.toString()

      if (this.currentPageId === stringPageId) return

      this.currentPageId = stringPageId
      this.currentRoute = this.shopRoutes.find((route) => route.pageId === stringPageId) || null
    },
    setUrlLanguages(UrlInLanguages: UrlInLanguage[]) {
      this.UrlInLanguages = UrlInLanguages.map((x) => {
        const url = new URL(x.Url)
        return { ...x, aspParams: this.currentRoute?.aspParams, path: url.pathname + url.search }
      })
    },
  },
})
