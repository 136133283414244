export function getDiamondsAndItemsCode(items: []) {
  const diamondsInBag: string[] = []
  const expressItemsInBag: string[] = []

  items.forEach((item) => {
    if (item.StockNumber) {
      expressItemsInBag.push(item.StockNumber)
    }

    if (item.Diamonds) {
      item.Diamonds.forEach((diamond) => {
        diamondsInBag.push(diamond.Code)
      })
    }
  })

  return {
    diamondsInBag,
    expressItemsInBag,
  }
}
